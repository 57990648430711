@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Open+Sans&display=swap&effect=anaglyph');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

:root {
  --body_background: rgb(251, 251, 251);
  --container_background: #fff;
  --item_background: rgb(249, 249, 249);
  --header_background: #fff;
  --font_color: #000;
  --button_background: #5886ac;
  --button_font_color: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--body_background);
  color: var(--font_color);
  background-image: url(./images/Repeating-Triangles.svg);
  background-image: url(./images/Pattern-Randomized.svg);
  background-image: url(./images/image001.jpg);
  background-image: url(./images/Liquid-Cheese.svg);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

h1,h2,h3 {
  font-family: 'Nunito', sans-serif;
}

h1 {
  text-align: center;
  flex-grow: 1;
  font-size: 50px;
}

.container {
  background-color: var(--container_background);
  width: 80vw;
  max-width: 1000px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 5px 0.2em rgba(0,0,0,5%);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 35%;
  margin-top: -100px;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
}

.task {
  background: var(--item_background);
  margin: 10px 5px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: inset 0 0 5px 0.1em rgba(0,0,0,2%);
  border-radius: 3px;
}

.task > div {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.task > div > p {
  border-bottom: 1px solid black;
  text-align: center;
  flex-grow: 0;
  width: 100px;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task > div > svg {
  margin-bottom: -3px;
}

.add-form {
  margin-bottom: 40px;
  /* height: 0px; */
  transition: height 0.2s ease;
  overflow: hidden;
}

.add-form > div{
  display: flex;
}

.add-form > div > div {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.form-control {
  margin: 20px;
}

.form-control label {
  display: block;
}

.form-control > input, .form-control > select, .form-control > div > input {
  width: 100%;
  height: 40px;
  margin: 0px;
  padding: 3px 7px;
  font-size: 17px;
  border-radius: 3px;
  border: 1px solid black;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}

.background_svg {
  fill: var(--body_background);
  width: 700px;
  height: 120px;
  margin-left: -270px;
  margin-top: -40px;
}

.material-icons {
  font-size: 50px;
}

.threshold {
  align-items: center;
  justify-content: center;
}

.ill_horse {
  background-image: url(./images/horse.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 180px;
  height: 120px;
}

.btn, #btn{
  font-family: 'Nunito', sans-serif;
  display: inline-block;
  background: var(--button_background);
  color: var(--button_font_color);
  border: none;
  padding: 10px 20px;
  margin: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  min-width: 94px;
}

.header > div> .btn {
  flex-grow: 0;
  margin: 5px 5px;
}

.header > div {
  margin-right: 20px;
}

.tm {
  width: 0.4em;
  height: 0.4em;
}

.checkSvg {
  height: 1.5em;
  width: 1.5em;
}

.popupContainer {
  background-color: var(--item_background);
  margin: 10px 5px;
  padding: 10px 20px;
  box-shadow: inset 0 0 5px 0.1em rgba(0,0,0,2%);
  border-radius: 3px;
}

.popupContainer > div:nth-child(2) {
  display: flex;
  max-height: 500px;
  overflow-y: scroll;
}

.popupContainer > div > ol {
  list-style-type: none;
  padding: 10px;
}

.popupContainer > div > ol > li {
  padding: 10px;
  margin: 2px;
}

.popupContainer> div > ol > li:nth-child(odd) {
  background-color: rgb(240, 240, 240);
  box-shadow: 0 0 5px 0.1em rgba(0,0,0,2%);
}

.popupContainer > div > h3 {
  display: flex;
  justify-content: space-between;
}

.logo {
  background-image: url(./images/logo.png);
  width: 280px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
}